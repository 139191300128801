<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		:showActionDelete="false"
		tooltipMsg="pimanualoftypes3487"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import loading from '@/mixins/loading.mixin';
import { model, fields } from './discount-type-form.data';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'DiscountType',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailof');
			const name = this.model.DiscountTypeNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.DiscountTypeId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},
};
</script>