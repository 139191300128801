import vueFormGenerator from 'vue-form-generator';

export const model = {
	DiscountTypeId: 0,
	DiscountTypeNameSk: null,
	DiscountTypeNameCz: null,
	DiscountTypeNamePl: null,
	DiscountTypeNameDe: null,
	DiscountTypeNameEn: null,
	DiscountTypeNameHu: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountTypeNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'switch',
				model: 'DiscountTypeHasNameValue',
				i18n: {
					label: 'ithasatextboxfo',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'DiscountTypeHasValue1',
				i18n: {
					label: 'ithasthestvalue',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'DiscountTypeHasValue2',
				i18n: {
					label: 'ithasandvaluefo',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'DiscountTypeHasValue3',
				i18n: {
					label: 'ithasardvaluefo',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
		],
	},
];
